<template>
  <div class="bg-white">
    <div class="mx-auto max-w-7xl py-16 px-4 sm:px-6 lg:py-20 lg:px-8">
      <div class="lg:grid lg:grid-cols-3 lg:gap-8">
        <div>
          <h2 class="text-3xl font-bold tracking-tight text-gray-900">Can't find what you're looking for?</h2>
          <p class="mt-4 text-lg text-gray-500">Reach out to our <a href="mailto:support@complaia.com" class="font-medium text-indigo-600 hover:text-indigo-500">customer support</a> team.</p>
        </div>
        <div class="mt-12 lg:col-span-2 lg:mt-0">
          <dl class="space-y-12">
            <div>
              <dd class="mt-2 text-base text-gray-500">
                Copyright © COMPLAIA SYSTEMS SP. Z O.O. All Rights Reserved.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                All files and information contained on this Website are copyrighted by COMPLAIA SYSTEMS SP. Z O.O. and may not be duplicated, copied, modified, or adapted, in any way without our written permission. Our Website may contain our service marks or trademarks as well as those of our affiliates or other companies, in the form of words, graphics, and logos.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Your use of our Website, and Services does not constitute any right or license for you to use our service marks or trademarks, without the prior written permission of COMPLAIA SYSTEMS SP. Z O.O.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                Our Content, as found within our Website, and Services, is protected under copyrights. The copying, redistribution, use or publication by you of any such Content, is strictly prohibited. Your use of our Website and Services does not grant you any ownership rights to our Content.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                ACCEPTABLE USE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
               <span class="font-bold">You must not:</span>
                <ol class="list-decimal list-inside mt-2">
                  <li>use our website in any way or take any action that causes, or may cause, damage to the website or impairment of the performance, availability or accessibility of the website;</li>
                  <li>use our website in any way that is unlawful, illegal, fraudulent or harmful, or in connection with any unlawful, illegal, fraudulent or harmful purpose or activity;</li>
                  <li>use our website to copy, store, host, transmit, send, use, publish or distribute any material which consists of (or is linked to) any spyware, computer virus, Trojan horse, worm, keystroke logger, rootkit or other malicious computer software;</li>
                  <li>or conduct any systematic or automated data collection activities (including without limitation scraping, data mining, data extraction and data harvesting) on or in relation to our website without our express written consent.</li>
                </ol>
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                REPORT ABUSE
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                If you found any unlawful material or activity on our website, or any material or activity that breaches this notice, please let us know.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                You can let us know about any such material or activity by emailing us at <a href="mailto:hello@complaia.com" class="no-underline">hello@complaia.com</a>.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                ENFORCEMENT OF COPYRIGHT
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                We take the protection of our copyright very seriously.
              </dd>
              <dd class="mt-2 text-base text-gray-500">
                If we discover that you have used our copyright materials in contravention of the license set out in this notice, we may bring legal proceedings against you, seeking monetary damages and/or an injunction to stop you from using those materials. You could also be ordered to pay legal costs.
              </dd>
            </div>
            <div>
              <dt class="text-lg font-medium leading-6 text-gray-900">
                PERMISSIONS
              </dt>
              <dd class="mt-2 text-base text-gray-500">
                You may request permission to use the copyright materials on our website by emailing us at hello@complaia.com.
              </dd>
            </div>
          </dl>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
    }
  }
}

</script>
